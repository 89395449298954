import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Instruction = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const examType = state?.id.examType
    const navigateTo=()=>{
        if(examType=="JAMB"){
            navigate("/exam/jamb-question")
        }
        if(examType=="UTME"){
            navigate("/exam/utme-question")
        }
        
    }

    return (
        <>
        <div className="jabm-sec d-flex align-items-center justify-content-center">
            <div className="jabm-wrap">
                <div className="quiListwrap">
                    <h3>Please read the following instructions carefully:</h3>
                    <ul className="list-style-number">
                        <li> This mode lets you have an experience on what the JAMB CBT is like.</li>
                        <li> You have a maximum of 2 hours to answer all questions.</li>
                        <li> Data connection is irrelevant after examination page has been fully loaded.</li>
                        <li> Click the Next and Back button to go through questions.</li>
                        <li> Or on your keyboard, press N to move to next question and press P to go back to previous question.</li>
                        <li> You can start with any subject of your choice.</li>
                        <li> A mobile calculator is available for subjects involving calculations.</li>
                        <li> Answered questions will be highlighted. This enables you to easily locate unanswered questions.</li>
                        <li> After completing the examination, click submit to view your result. Data connection will be required to submit examination.</li>
                        <li> Examination will automatically stop when your time has been exhausted. When this happens, click the submit button to obtain result.</li>
                        <li> Your score will be displayed and your result analysis will be shown along with correct answers and solutions to failed questions.</li>
                    </ul>
                </div>
                <div className="qucomm-btn-wrap text-center pb-3">
                    <button className="btn" onClick={()=>{navigateTo()}}>Start Examination</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default Instruction;