import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../services";
import { CHECK_JAMB_SUBJECTS, GET_DISTINCT_YEAR_PAPER, GET_USER_EXAM_DETAILS } from "../../services/ApiConstant";
import { Button, Modal } from "antd";

const JAMBSimulation = () => {
  const [error, setError] = useState()
  const navigate = useNavigate();
  const proceed = () => {

    if (yearSelected !== undefined) {
      getUserData(CHECK_JAMB_SUBJECTS + `?exams_subjects=${selectedSubject}&paper_year=${yearSelected}`).then((res) => {
        if (res.data.status) {
          localStorage.setItem('yearSelected', yearSelected);
          localStorage.setItem("selectedTestpaper", selectedSubject)
          // navigate("/exam/instruction")
          navigate("/exam/instruction", {
            state: {
              id: { examType: "JAMB" }
            }
          })

        }
        else {
          setIsModalOpen2(true)


          setErrorMessage(res.data.message)
        }
      })

    }
    else {
      setError("please select year")
    }
  }

  const [subjects, setSubject] = useState()
  const [year, setYear] = useState([])
  const [yearSelected, setYearSelected] = useState()
  const [bunchOfTestpaper, setBunchOfTestPaper] = useState([])
  const [selectedSubject, setSelectedSubject] = useState([])

  useEffect(() => {
    let examID = localStorage.getItem("examID")
    getUserData(GET_DISTINCT_YEAR_PAPER + `?exam_id=${examID}`).then((res) => {
      setBunchOfTestPaper(res.data)
      let array = []
      res.data?.map((i) => {
        !array.includes(i.year) && setYear(year => [...year, i.year])
        array.push(i.year)
      })
    });
  }, [])

  const [yearSort, setYearSort] = useState([])

  useEffect(() => {
    const sortedNumbers = [...year];
    sortedNumbers.sort((a, b) => a - b); // For ascending order
    console.log(sortedNumbers)
    setYearSort(sortedNumbers)

  }, [year])


  useEffect(() => {
    let examID = localStorage.getItem("examID")
    getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
      setSubject(res.data)
      if (res.data.length === 0) {
        setError("No TestPaper available for this Subject")
      }
    });
  }, [])

  useEffect(() => {
    setError('')
  }, [yearSelected])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    selectedSubject?.length > 4 ?
      setIsModalOpen(true)
      :
      setIsModalOpen(false)
    setYearSelected()

  }, [selectedSubject])


  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleCancel2 = () => {
    setIsModalOpen2(false)
  }


  return (
    <>
      <Modal
        className="subModal"
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleCancel}
        width={316}
        footer={[
          <Button key="back" className="okbutton" onClick={handleCancel}>
            OK
          </Button>,
        ]}
      >
        <div className="subjectModal">
          <p className="pb-3">
            Only 4 subjects can be selected
          </p>
        </div>
      </Modal>

      <Modal
        className="subModal"
        open={isModalOpen2}
        onCancel={handleCancel2}
        onOk={handleCancel2}
        width={316}
        footer={[
          <Button key="back" className="okbutton" onClick={handleCancel2}>
            OK
          </Button>,
        ]}
      >
        <div className="subjectModal">
          <p className="pb-3">
            {errorMessage}
          </p>
        </div>
      </Modal>


      <div className="jabm-sec d-flex align-items-center justify-content-center">
        <div className="jabm-wrap text-center">
          <h1 className="quttl">JAMB CBT Simulator</h1>
          <div className="quTextboxa">
            <p className="qupara"> Please select the four subjects you intend to register for in your actual JAMB Exam.</p>
          </div>
          <div className="radio-btn-wrap align-items-center justify-content-center pb-5">
            {subjects?.map((i) =>
            (<div className="btn-box">
              <input type="checkbox" id={i.exam_subject_id} name={i.subject_name} checked={selectedSubject.includes(i.exam_subject_id)} value={i.exam_subject_id} onChange={() => selectedSubject.includes(i.exam_subject_id) ? setSelectedSubject(selectedSubject?.filter(item => item !== i.exam_subject_id)) : selectedSubject.length <= 3 ? setSelectedSubject([...selectedSubject, i.exam_subject_id]) : setIsModalOpen(true)} />
              <label htmlFor={i.exam_subject_id}>{i.subject_name}</label>
            </div>))
            }

          </div>
          {selectedSubject?.length == 4 && <>
            <h3 className="pb-4">SELECT YEAR</h3>
            <div className="radio-btn-wrap pb-4 justify-content-center">
              {
                yearSort?.map((i) => (
                  <div className="btn-box">
                    <input type="radio" id={i} name="year" value={i} onClick={(e) => setYearSelected(e.target.value)} />
                    <label htmlFor={i}>{i}</label>
                  </div>
                ))
              }
            </div>
          </>}
          {<div className="error-msg">{error}</div>}
          {<div className="qucomm-btn-wrap" >
            <button disabled={!(yearSelected !== undefined)} className="btn" onClick={() => proceed()}>Proceed</button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default JAMBSimulation;