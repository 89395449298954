import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { CiSearch } from 'react-icons/ci';
import { Button, Input, Modal } from 'antd'
import { getAuthRequest, getRequest, getUserData } from "../../services";
import { GET_HELP_PER_EXAM, GET_SEACH_TEXT, PROFILE_API } from "../../services/ApiConstant";
import { MyContext } from "../../assets/common/MyContext";


const Header = ({ step, page, exam, examsimulation, updatedData, userProfileImage }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [fullData, setFullData] = useState()
  const [searchText, setSearchText] = useState("")
  const { setSearchData } = useContext(MyContext);
  const { setSearchWord } = useContext(MyContext);

  const examID = localStorage.getItem("examID")
  const [helpData, setHelpData] = useState()
  const LinkArray = window.location.href.split("/")

  const [toggleMenu, setToggleMenu] = useState(false);

  const [helpButton, setHelpButton] = useState(null)
  const [path,setPath]=useState('')

  useEffect(() => {
    {
      examID !== null && getRequest(GET_HELP_PER_EXAM + `?exam_id=` + examID).then((res) => {
        setHelpData(res.data.data)
        setHelpButton(res.data.status)

      })
    }
  }, [examID])

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu)
    if (!toggleMenu) {
      document.querySelector('body').classList.add("active-student_dashb_header")
    } else {
      document.querySelector('body').classList.remove("active-student_dashb_header")
    }
  }

  const getData = () => {
    !examsimulation && getAuthRequest(PROFILE_API, {}).then((res) => {
      setFullData(res.data)
      setStatus(true)
    }).catch((e) => {
    })
  }
  useEffect(() => {
    getData()
  }, [])

  const [modal1Open, setModal1Open] = useState(false);
  const [sureBack, setSureBack] = useState(false);

  const help = () => {
    setModal1Open(true)
  }

  const clickHandler = () => {
    navigate('/dashboard/profile-setting')
  }

  const serachFunction = (value) => {
    // setSearchText(value)
    if (searchText?.length >= 3) {
      getUserData(GET_SEACH_TEXT + `?search=${value}`, {}).then((res) => {
        navigate("/dashboard/search")
        setSearchData(res.data)
      }).catch((e) => {
      })
    }
    else {
      setSearchData()
    }
  }

  useEffect(() => {
    serachFunction(searchText)
    setSearchWord(searchText)

    // !window.location.pathname.includes("/home")&& navigate("/dashboard")
  }, [searchText])

  console.log(LinkArray)

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    alert("helo")
    setImageError(true);
  };

  useEffect(()=>{
        !window.location.pathname.includes("/search")&& setPath(window.location.pathname)
  },[searchText])

  console.log(path,window.location.pathname)

  return (
    <>
      <div className='header stickyhead row-between p-20 student_dashb_header'>
        {!LinkArray.includes("exam") && <button type="button" className={toggleMenu ? "menu-toggle active" : "menu-toggle"} onClick={() => handleToggleMenu()}>
          <div></div>
        </button>}
        <div className={examsimulation ? "logo-box-help" : `logo-box`}>
          <img className="logo" style={{cursor:"pointer"}} src="/images/logo.svg" onClick={() => { LinkArray.includes("exam") ? setSureBack(true) : navigate("/dashboard/home") }} alt="icon" />
        </div>

        {!exam && <div className="center-search-box">
          <Input value={searchText} onChange={(e) => { setSearchText(e.target.value); e.target.value === "" && navigate(path) }}
            size="small" placeholder="Search...." prefix={<CiSearch color="#7F97BD" />} />
          <button disabled={searchText==''} onClick={() => { setSearchText(''); navigate(path) }} className="closebtn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>
        </div>
        }
        {!exam &&
          <div className="user-info-wrap profile-cursor" onClick={clickHandler}>
            <div className="tham">
              {/* <img className='profile-img' src={fullData?.profile_image===null?require("../../assets/images/default-user-icon.png"): userProfileImage==undefined?fullData?.profile_image:userProfileImage} /> */}
              {!imageError && <img className="profile-img" src={userProfileImage || fullData?.profile_image || require("../../assets/images/default-user-icon.png")} onError={handleImageError} onLoad={() => setImageError(false)} alt="picture" />
              }
              {imageError && <img className="profile-img" src={require("../../assets/images/default-user-icon.png")} alt="picture" />
              }
            </div>
            {status ?
              <div className="user-info">
                <h3>{updatedData?.full_name ? updatedData?.full_name : fullData?.full_name}</h3>
                <p>{fullData?.user?.email}</p>
              </div> : ""}
          </div>
        }
        {examID !== null && <Button disabled={!helpButton} onClick={() => help(true)}>Help <img src={require("../../assets/images/clarity_help-info-solid.png")} /> </Button>}

        {examsimulation && <Modal
          title="help"
          style={{
            top: 20,
            right: 10
          }}
          open={modal1Open}
          onCancel={() => setModal1Open(false)}
          onOk={() => setModal1Open(false)}

        >

          <div className="helpModal">
            <i>{helpData?.help_text}</i>
            <div className="exQupanel">
              <div className="qutext">
                <h3>{helpData?.question_text}</h3>
              </div>
              <div className="quoplist">
                <ul className="cusradiolist">
                  <li key="quisodio1">
                    <input type="radio" id="quisodio1" name="quisodio" value="" />
                    <label for="quisodio1">(A)  {helpData?.A}</label>
                  </li>
                  <li key="quisodio2">
                    <input type="radio" id="quisodio2" name="quisodio" value="" />
                    <label for="quisodio2">(B)  {helpData?.B}</label>
                  </li>
                  <li key="quisodio3">
                    <input type="radio" id="quisodio3" name="quisodio" value="" />
                    <label for="quisodio3">(C)  {helpData?.C}</label>
                  </li>
                  <li key="quisodio4">
                    <input type="radio" id="quisodio4" name="quisodio" value="" />
                    <label for="quisodio4">(D)  {helpData?.D}</label>
                  </li>
                  <li key="quisodio5">
                    <input type="radio" id="quisodio5" name="quisodio" value="" />
                    <label for="quisodio5">(E)  {helpData?.E}</label>
                  </li>
                </ul>
              </div>
            </div>
            <p>{helpData?.question_explaination}</p>
            <p>{helpData?.question_note}</p>

          </div>
        </Modal>}

        <Modal

          style={{
            top: 20,
            right: 10
          }}
          open={sureBack}
          onOk={() => navigate("/dashboard/home")}
          onCancel={() => setSureBack(false)}
        >

          Are you sure you want to  go to home

        </Modal>

      </div>
      <div className="student_dashb_header-overlay" onClick={() => handleToggleMenu()}></div>
    </>
  );
};

export default Header;
