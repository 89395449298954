import React from 'react'

const Error = () => {
  return (
    <div style={{display:'flex',height:'100vh',justifyContent:'center',alignItems:'center'}}>
      No Route Matched With This Path
    </div>
  )
}

export default Error
