import React, { Fragment, useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { getRequest, postRequest } from "../../../services";
import {
  GET_EXAM_TYPES,
  POST_USER_CATEGORY,
} from "../../../services/ApiConstant";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const ExamType = () => {
  const [error, setError] = useState();
  const [contextHolder] = message.useMessage();
  const [examTypes, setExamTypes] = useState([]);
  const [currentId, setCurrentId] = useState();

  const examTypesArray = Array.isArray(examTypes) ? examTypes : [];

  const navigate = useNavigate();

  useEffect(() => {
    getRequest(GET_EXAM_TYPES).then((res) => {
      setExamTypes(res?.data);
    });
  }, []);

  console.log("examTypes", examTypesArray);

  const updateExamType = (id) => {
    setCurrentId(id);
    postRequest(POST_USER_CATEGORY + `?category_id=${id}`)
      .then(() => {
        navigate("/pricing", { state: { id: id } });
        // payment(id);
      })
      .catch((e) => {
        setError(e.response.data.error[0]);
      });
  };

  return [
    <AuthCard
      link="/"
      linkText={""}
      subtitle=""
      page="Choose Exam Type"
      step="02"
    >
      <br />
      {contextHolder}
      <p className="subtitle">
        Choose Exam Type you wish to register for from the underlisted Category
      </p>
      {examTypesArray.length > 0 &&
        examTypesArray?.map(({ name, description, icon, id }, index) => (
          <Fragment key={index}>
            <div className="exam-card" onClick={() => updateExamType(id)}>
              <img src={icon} className="icon" />
              <div className="row-between">
                <div className="pr-20">
                  <div className="title">{name}</div>
                  <div className="desc">{description}..</div>
                </div>
                <div
                  className="row-center pr-10"
                  onClick={() => updateExamType(id)}
                >
                  {" "}
                  console.log("examTypes", examTypes);
                  <AiOutlineArrowRight size={"24px"} color="#2F327D" />
                </div>
              </div>
            </div>
            {id == currentId && <div className="error-msg">{error}</div>}
          </Fragment>
        ))}
    </AuthCard>,
    <div className="footer-text">
      NOTE: We're excited to offer you a 7-day free trial. You'll have full
      access to the exam of your choice during this time, completely free of
      charge. After the trial period, your account will be automatically billed
      for the subscription fee. You can cancel your subscription within the
      period of this 7 days.
    </div>,
  ];
};

export default ExamType;
