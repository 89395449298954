import { useEffect, useState } from "react";
import { getAuthRequest, getRequest, getUserData } from "../../services";
import {
  AMBASSADOR_ALL_REFERRALS,
  PROFILE_API,
} from "../../services/ApiConstant";
import { Select, message } from "antd";

const AmbassodorOverview = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [path, setPath] = useState();
  const [refrerAccount, setRefrerAccount] = useState([]);
  const [refrerDetails, setRefrerDetails] = useState();
  const [profile, setProfile] = useState();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  // const [link, setLink] = useState(`?limit=5&offset=0`)
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentYear = new Date().getFullYear();
  const year = Array.from({ length: 5 }, (_, index) =>
    (currentYear - index).toString(),
  );
  const [filterMonth, setFilterMonth] = useState(1);
  const [filterYear, setFilterYear] = useState(year[0]);

  const NextPreButton = (url) => {
    if (url != null) {
      let loc = new URL(url);
      let search = new URLSearchParams(loc.search);
      setLimit(search.get("limit"));
      search.get("offset") == null
        ? setOffset(0)
        : setOffset(search.get("offset"));
    }
  };

  useEffect(() => {
    getAuthRequest(PROFILE_API, {})
      .then((res) => {
        setProfile(res.data);
      })
      .catch((e) => { });
    setLimit(10);
    setPath("https://student.excelmind.org");
    navigator.geolocation.getCurrentPosition((position) => { });
  }, []);

  useEffect(() => {
    setLimit(limit <= 0 ? 10 : limit);
    getUserData(
      `${AMBASSADOR_ALL_REFERRALS}/?limit=${limit}&offset=${offset}&month=${filterMonth}&year=${filterYear}`,
    ).then((res) => {
      setRefrerDetails(res.data);
      setRefrerAccount(res.data.results);
      setTotal(res.data.count);
    });
  }, [offset, limit, filterMonth, filterYear]);

  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages([]);
    let i = 0;
    i = Math.ceil(total / limit);

    for (let index = 0; index < i; index++) {
      setPages((pages) => [...pages, index * limit]);
    }
  }, [total, limit]);

  const info = () => {
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  const copyText = () => {
    navigator.clipboard.writeText(
      `${path}/create-account?refer_code=${profile?.refer_code}`,
    );
    info();
  };

  const changingLimit = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const d = new Date();
  const currentMonth = d.getMonth();

  useEffect(() => {
    setFilterMonth(currentMonth + 1);
  }, []);

  return (
    <>
      {contextHolder}
      <div className="ambcardpanel">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-ms-12 ambcardpanel-col">
            <div className="box">
              <p>
                Share your referral link with with anyone to earn #1000 on every
                referrals.
              </p>
              <div className="copycode">
                <button onClick={() => copyText()}>
                  <span>{`${path} /create-account?refer_code=${profile?.refer_code}`}</span>
                  <img src="/images/copy-icon.svg" alt="copy" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-ms-12 ambcardpanel-col">
            <div className="box">
              <div className="eartop">
                <h4>₦ {profile?.balance ? profile?.balance : 0}</h4>
                <img src="/images/earning-icon.svg" />
              </div>
              <strong>TOTAL EARNING</strong>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-ms-12 ambcardpanel-col">
            <div className="box">
              <div className="eartop">
                <h4>{total}</h4>
                <img src="/images/member-icon.svg" />
              </div>
              <strong>TOTAL REFERRALS</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="commtablearea">
        <div className="ttl">
          <h3>REFERRALS</h3>
          <div>
            <select
              onChange={(e) =>
                setFilterMonth(month.indexOf(e.target.value) + 1)
              }
            >
              {month?.map((i) => (
                <option value={i} selected={i == month[currentMonth]}>
                  {i}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => setFilterYear(e.target.value)}
              style={{ marginLeft: "10px" }}
            >
              {year?.map((i) => (
                <option value={i}>{i}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="tablewrap">
          <table>
            <thead key="thead">
              <tr key="theadrow">
                <th key="s/n">S/N</th>
                <th key="name">NAME</th>
                <th key="email">EMAIL</th>
                <th key="subcription">SUBSCRIPTION</th>
              </tr>
            </thead>
            <tbody key="tbody">
              {refrerAccount?.length === 0 ? (
                <tr key="no data row">
                  <td colSpan={4}>
                    <div className="no-data-show-msg-sec">
                      <div className="icon-container">
                        <img src="/images/no-data-show.svg" alt=""></img>
                      </div>
                      <div className="text-container">No data to show</div>
                    </div>
                  </td>
                </tr>
              ) : (
                refrerAccount?.map((i, index) => (
                  <tr key={index}>
                    <td key={index + Number(offset) + 1} th-name="S/N :">
                      {index + 1 + Number(offset)}
                    </td>
                    {/* {me=me+1} */}
                    <td key={index + i.full_name} th-name="NAME :">
                      {i.full_name}
                    </td>
                    <td key={index + i.email} th-name="EMAIL :">
                      {" "}
                      {i.email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")}
                    </td>
                    <td key={index + i.subscription} th-name="SUBSCRIPTION :">
                      {i.subscription}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {refrerAccount?.length === 0 ? (
          ""
        ) : (
          <div className="tablepagi">
            <ul>
              <li className="nxp-btn" key="Previous">
                <a onClick={() => NextPreButton(refrerDetails.previous)}>
                  Previous
                </a>
              </li>
              {pages?.map((no, index) => (
                <li key={index} className={no == offset && "page-active"}>
                  <a onClick={() => setOffset(no)}>{index + 1}</a>
                </li>
              ))}
              <select
                className="pageNumbers"
                onChange={(e) => {
                  changingLimit(e);
                }}
              >
                <option key={10} value={10}>
                  10
                </option>
                <option key={25} value={25}>
                  25
                </option>
                <option key={50} value={50}>
                  50
                </option>
                <option key={100} value={100}>
                  100
                </option>
              </select>

              <li className="nxp-btn" key="Next">
                <a onClick={() => NextPreButton(refrerDetails.next)}>Next</a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default AmbassodorOverview;
