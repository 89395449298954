import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import LevelExam from "./LevelExam";
import { getRequest, getUserData } from "../../services";
import { GET_BLOG_DETAILS, GET_EXAM_TYPES } from "../../services/ApiConstant";
import { MyContext } from "../../assets/common/MyContext";

const OtherExam = ({ step, page }) => {
  const [showMore, setShowMore] = useState(null);
  const onChange = () => { };
  const [examTypes, setExamTypes] = useState([]);
  const [blog, setBlog] = useState([]);
  const items = [];
  const examTypesArray = Array.isArray(examTypes) ? examTypes : [];

  const { searchData } = useContext(MyContext);

  useEffect(() => {
    getUserData(GET_EXAM_TYPES).then((res) => {
      setExamTypes(res?.data);
    });
    getRequest(GET_BLOG_DETAILS).then((res) => {
      setBlog(res?.data);
    });
  }, []);

  searchData == undefined
    ? examTypesArray.map((item, index) => {
      items[index] = {
        label: item?.name,
        key: index.toString(),
        children: <LevelExam item={item} />,
      };
    })
    : examTypesArray.map((item, index) => {
      if (searchData?.category == item.id) {
        items[index] = {
          label: item?.name,
          key: index.toString(),
          children: <LevelExam item={item} />,
        };
      }
    });

  // const blogHandler=(item)=>{
  //   const data=blog?.filter((currBlog)=>{
  //     return currBlog?.title==item
  //   })[0]?.external_link
  //   window.location.replace(data)
  // }

  return (
    <div
      className="home"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="home-left others-exam-body">
        <Tabs
          size="large"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>

      <div className="home-right for-exam">
        <h1>Blogs</h1>
        {blog && (
          <div className="right-blog-lists">
            {blog?.map((item, index) => (
              <div className="right-blog-list-item">
                <div className="image-container">
                  <a href={item?.external_link} target="_blank">
                    <img src={item.icon} />
                  </a>
                </div>
                <div className="text-container">
                  <a href={item?.external_link} target="_blank">
                    <h4>{item.title}</h4>
                  </a>
                  {/* <BlogData details={item.details} /> */}
                  <p className="desc">
                    {showMore == index
                      ? item.details
                      : `${item.details.substring(0, 50)}`}
                    {!(showMore == index) ? (
                      <a
                        name={index}
                        href={item?.external_link}
                        target="_blank"
                        className="blog-read-more"
                      >
                        {" "}
                        Read more
                      </a>
                    ) : (
                      <a
                        name={index}
                        className="blog-read-more"
                        onClick={(e) => {
                          setShowMore(null);
                        }}
                      >
                        {" "}
                        Show less
                      </a>
                    )}
                    {/* onClick={(e) => { setShowMore(e.target.name) }} */}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherExam;
