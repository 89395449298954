import React, { Fragment, useEffect, } from "react";
import { Link } from "react-router-dom";
import AuthHeader from "../AuthHeader";
import { useLocation } from "react-router-dom";

const AuthCard = ({
  step,
  page,
  bank,
  isHide,
  children,
  subtitle,
  link,
  linkText,
  loginPage,
  logo,
  title,
}) => {
  const location = useLocation();
  const pathName = location.pathname;
  const currentComponent = pathName.substring(1);
  // const [logo, setLogo] = useState(false);

  useEffect(() => {
    let role = 2;

    if (location.search === "?2") {
      role = 2;
    } else {
      role = 2;
    }

    if (role === 2) {
      logo = false;
      // setLogo(false);
    }
  }, [location.search]);

  return [
    <>
      {!isHide && <AuthHeader {...{ step, page, currentComponent }} />}

      <div className="signin-container-wrap">
        <div
          className={
            logo ? "signin-container" : "signin-container for-ambassador"
          }
        >
          {/* <img className="logo" src={require(logo?"../../assets/images/logo.png":"../../assets/images/Ambassador-logo.png")} /> */}
          {logo ? (
            <img className="logo" src="/images/logo.svg" alt="" />
          ) : (
            <img className="logo" src="/images/Ambassador-logo.svg" alt="" />
          )}
          {!loginPage && (
            <Fragment>
              <h1
                className={logo ? " text-center" : "for-ambassador text-center"}
              >
                {title ? title : "Hello, welcome to ExcelMind!"}
              </h1>
              <p className="signup_more_link">
                {subtitle}&nbsp;
                <Link to={link}>
                  <span>{linkText}</span>
                </Link>
              </p>
            </Fragment>
          )}

          <div
            className={
              !bank ? "form-content-wrapper" : "form-content-wrapper pt-0"
            }
          >
            {children}
          </div>
        </div>
        {/* {loginPage && <p className="signup_more_link">
            {subtitle}&nbsp;
            <Link to={link}>
              <span>{linkText}</span>
            </Link>
          </p>} */}

        {loginPage && (
          <p className="signup_more_link">
            {subtitle}&nbsp;
            <Link to={link}>
              <span>{linkText}</span>
            </Link>
          </p>
        )}
      </div>
    </>,
  ];
};

export default AuthCard





















































 
  


    
      
        
          
        
        
      
    


  
  
 
 
  
 
 
 
 
    
 
 
 
 
 
 
 
 
 
      
        
        
        
    


  
 
  


 
 
 
 
             
  
