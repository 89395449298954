import { message } from "antd";
import { useLocation } from "react-router-dom";
import AuthCard from "../../../Components/AuthCard";
import { postAuthRequest } from "../../../services";
import { TRANSACTION } from "../../../services/ApiConstant";

export default function PreferedSubscription() {
  const [messageApi] = message.useMessage();
  const { state } = useLocation();
  const id = state.id;

  const PaymentInfo = () => {
    messageApi.open({
      type: "success",
      content: "Subscribed to plan",
    });
  };

  const payment = (id) => {
    postAuthRequest(TRANSACTION, { category_id: id }).then((res) => {
      res.data.message != undefined && PaymentInfo();
      window.location.href = res.data.result.data.authorization_url;
    });
  };

  return (
    <AuthCard
      link={"/exam-type"}
      page="Choose Exam Body"
      step="03"
      title={"Choose your preferred subscription"}
      logo
    >
      <div className="pricing__box">
        <div className="pricing__item">
          <div className="pricing__price">
            <h3>₦4,497</h3>
            <p>Per Month</p>
          </div>
          <button
            onClick={() => {
              payment(id);
            }}
            className="pricing__btn"
          >
            Start 3 Days Free Trial
          </button>
        </div>
        <div className="pricing__item pricing__item--recommended">
          <div className="pricing__price">
            <h3>₦15,997</h3>
            <p>Per Term (Every 4 months)</p>
          </div>
          <button className="pricing__btn">Start 3 Days Free Trial</button>
        </div>
      </div>
    </AuthCard>
  );
}
