import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ isLoggedIn, children }) => {
  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const PrivateRoute2 = ({ isLoggedIn, children }) => {
  let role = localStorage.getItem("userType");
  if (!isLoggedIn) {
    return children;
  } else {
    if (role === 1) {
      return <Navigate to="/dashboard/home" />;
    }
    if (role === 2) {
      return <Navigate to="/ambassodor/ambassodor-overview" />;
    }
  }
};

export { PrivateRoute, PrivateRoute2 };
